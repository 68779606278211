.payment_box_shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.text-gray {
  color: gray !important;
}

.cursor-pointer{
  cursor: pointer;
}