* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 3.125rem #fff inset !important;
}

/* ---------- Form Input ---------- */
.form-control,
.form-select {
  font-size: 14px !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #65b2eb;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: black;
}

.form-control:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.tableScrollNone::-webkit-scrollbar {
  display: none;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1.4px solid #e75126 !important;
}

.Mui-selected {
  background-color: #fcf1eb !important;
}

.text-red {
  color: red !important;
}

.error-text {
  font-weight: 500;
  margin-top: 2px;
  font-size: 0.875rem;
  padding-left: 1.875rem;
}
/* Pagination Container */

.pagination {
  display: flex;
  list-style-type: none;
  padding-right: 3rem;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  /* position: absolute;
  bottom: 0;
  right: 0; */
}

.pagination-border-radius .pagi-item a {
  border-radius: 5px;
}

/* Pagination Items */
.pagi-item {
  margin: 0 5px;
}

/* Pagination Links */
.pagi-link {
  padding: 3px 7px;
  border: 1px solid #ddd;
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.pagination-link {
  transition: all 0.3s ease;
}

.pagination-link:hover {
  background-color: #f0f0f0;
}

/* Active Page */
.page-active a {
  background-color: #167348;
  color: white;
  border-color: #167348;
}

/* Next and Previous Buttons */
.next_previous_classname a {
  font-weight: bold;
  text-decoration: none;
  color: #167348;
  margin: 0 10px;
}

/* Break Label */
.break-me {
  padding: 3px 7px;
  color: #666;
}

.view_all {
  color: #167348;
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}

.overflow_email_invi_modal {
  height: 50vh !important;
  overflow-y: auto !important;
}

.view_all_height {
  height: 60vh !important;
  overflow-y: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
